.footer{
  width: 640px;
  padding: 0px 0;    
  overflow: hidden;
  margin: 0 auto;
}

.disclaimer {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 11px;
  color: #666;
  margin: 0;
  margin-top: 20px;
  text-align: justify;
  position: relative;
  left: 10px;
}

.copy {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #fff;
  margin: 0;
  margin-top: 20px;
  text-align: center;
  position: relative;
  left: 10px;
}

.contractInfo {
  font-size: 12px;
  color: #fff;
}

.contractInfo a {
  font-size: 12px;
  color: #ccc;
  text-decoration: none;
}

@media screen and (max-width: 926px) {
  .footer{
    width: 360px;
    padding: 0px 0;    
    overflow: hidden;
    margin: 0 auto;
  }
  .contractInfo {
    font-size: 10px;
    color: #fff;
  }
  .contractInfo a {
    font-size: 10px;
    color: #ccc;
    text-decoration: none;
  }
}
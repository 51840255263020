.connects {
  float: left;
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
  width: 160px;
  color: #db7901a4;
  cursor: pointer;
  font-size: 14px;
  background-color: #00000020;
  font-family: oxanium;
}

.connects:hover {
  color: #db7901;
  background-color: #00000050;
}

.connects img {
  width: 30px;
  float: left;
  margin-right: 12px;
}
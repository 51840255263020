.walletInfo {
  width: 100%;
  text-align: right;
  justify-content: left;
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  color: #777777;
  background-color: #00000096;
  border-radius: 5px;
}

.balanceLunc {
  position:relative;
  text-align: right;
  font-weight: 600;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  background-color: #f9d85e;
  margin: 10px;
}

.balanceUstc {
  position:relative;
  text-align: right;
  font-weight: 600;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  background-color: #5493f7;
  margin: 10px;
}

.balanceLuncGalaxy {
  position:relative;
  right:140px;
  top:18px;
  text-align: right;
  font-weight: 600;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  background-color: #f9d85e;
  margin: 10px;
}

.balanceUstcGalaxy {
  text-align: right;
  font-weight: 600;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  background-color: #5493f7;
  margin: 10px;
}


.decimalPart{
  font-size: 10px;
  }
  

@media (max-width: 768px) {
  .walletInfo {
    position:relative;
    right:10px;
    width: 100%;
    text-align: right;
    justify-content: left;
    padding: 5px;
    margin: 10px;
    font-size: 14px;
    color: #777777;
    background-color: #00000096;
    border-radius: 5px;
  }

  .balanceLunc {
    position:relative;
    bottom:5px;
    left:0px;
    text-align: right;
    font-weight: 600;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    background-color: #f9d85e;
    margin: 10px;
  }
  
  .balanceUstc {
    position:relative;
    bottom:5px;
    left:0px;
    text-align: right;
    font-weight: 600;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    background-color: #5493f7;
    margin: 10px;
  }
  
  .balanceLuncGalaxy {
    position:relative;
    right:120px;
    top:18px;
    text-align: right;
    font-weight: 600;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    background-color: #f9d85e;
    margin: 10px;
  }
  
  .balanceUstcGalaxy {
    text-align: right;
    font-weight: 600;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    background-color: #5493f7;
    margin: 10px;
  }
}